<template>
    <div class="prtf-page-header-subtitle_item link" @click="open_instrument_page">
        {{instrument.isin}} <span v-if="position_type !== 'long'">[{{position_type}}]</span>
    </div>
</template>

<script>
export default {
    props: {
        instrument: {
            required : true,
            type     : Object,
        },
        position_type: {
            required : false,
            type     : String,
            default  : 'long'
        },
    },

    methods: {
        open_instrument_page(){
            // this.$router.push('/instrument/' + this.instrument.asset + '/' + this.instrument.isin)
        }
    }
}
</script>
