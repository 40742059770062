var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageBlock',{attrs:{"title":"Variation Margin","loading":_vm.loading,"can-export":"","can-fullscreen":""},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" Tick value: "),_c('b',[_vm._v(_vm._s(_vm.instrument.tickv))]),_vm._v(" Tick size: "),_c('b',[_vm._v(_vm._s(_vm.instrument.ticks))]),_vm._v(" Trade Period: "),_c('b',[_c('FormattedDate',{attrs:{"value":_vm.instrument.futbdate}}),_vm._v(" - "),_c('FormattedDate',{attrs:{"value":_vm.instrument.stdt}})],1)]},proxy:true}])},[_c('vxe-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"common-grid",attrs:{"data":_vm.list,"size":"mini","auto-resize":"","footer-method":_vm.footer_method,"show-footer":"","footer-row-class-name":"common-grid__footer"}},[_c('vxe-table-column',{attrs:{"field":"dt","title":"Date","width":"100","formatter":"formatDate"}}),_c('vxe-table-column',{attrs:{"field":"isin","title":"Currency","width":"100"}}),_c('vxe-table-column',{attrs:{"title":"Variation Margin Amount","min-width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.q,"decimals":2,"color":"","sign":""}})]}},{key:"footer",fn:function(ref){
var items = ref.items;
var _columnIndex = ref._columnIndex;
return [_c('FormattedNumber',{attrs:{"value":items[_columnIndex],"decimals":2,"color":"","sign":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Prev Day Position","width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.props.prev_q,"color":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Previous Day Price","width":"140","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.props.prev_price,"decimals":4}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Position","width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.props.q,"color":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Price","width":"140","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.props.price,"decimals":4}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }