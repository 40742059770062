var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageBlock',{attrs:{"title":"Accruals and Cash Income","loading":_vm.loading,"can-export":"","can-fullscreen":""}},[_c('vxe-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"common-grid",attrs:{"data":_vm.list,"size":"mini","auto-resize":"","footer-method":_vm.footer_method,"show-footer":"","footer-row-class-name":"common-grid__footer"}},[_c('vxe-table-column',{attrs:{"field":"exp_dt","title":"Cut-Off Date","min-width":"100","formatter":"formatDate"}}),_c('vxe-table-column',{attrs:{"field":"exp_q","title":"Position","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.exp_q,"decimals":2,"color":"","sign":""}})]}}])}),_c('vxe-table-column',{attrs:{"field":"exp_per_one","title":"Accruals Amount per One","min-width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.exp_per_one,"decimals":4}})]}}])}),_c('vxe-table-column',{attrs:{"field":"exp_cur","title":"Accruals Currency","min-width":"100"}}),_c('vxe-table-column',{attrs:{"field":"exp_am","title":"Accruals Amount","min-width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.exp_am,"decimals":2,"color":"","sign":""}})]}},{key:"footer",fn:function(ref){
var items = ref.items;
var _columnIndex = ref._columnIndex;
return [_c('FormattedNumber',{attrs:{"value":items[_columnIndex],"decimals":2,"color":"","sign":""}})]}}])}),_c('vxe-table-column',{attrs:{"field":"fact_dt","title":"Cash Income Date","min-width":"100","formatter":"formatDate"}}),_c('vxe-table-column',{attrs:{"field":"fact_cur","title":"Cash Income Currency","min-width":"100"}}),_c('vxe-table-column',{attrs:{"field":"fact_am","title":"Cash Income Amount","min-width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.fact_am,"decimals":2,"color":"","sign":""}})]}},{key:"footer",fn:function(ref){
var items = ref.items;
var _columnIndex = ref._columnIndex;
return [_c('FormattedNumber',{attrs:{"value":items[_columnIndex],"decimals":2,"color":"","sign":""}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }