<template>
    <PageBlock 
        title="Variation Margin"
        :loading="loading"
        can-export
        can-fullscreen>

        <template v-slot:subtitle>
            Tick value: <b>{{instrument.tickv}}</b>
            Tick size: <b>{{instrument.ticks}}</b>
            Trade Period: <b><FormattedDate :value="instrument.futbdate" /> - <FormattedDate :value="instrument.stdt" /></b>
        </template>

        <vxe-table 
            :data="list" 
            size="mini" 
            class="common-grid" 
            v-loading="loading"
            auto-resize
            :footer-method="footer_method"
            show-footer
            footer-row-class-name="common-grid__footer">

            <vxe-table-column 
                field="dt" 
                title="Date" 
                width="100"
                formatter="formatDate">
            </vxe-table-column>
            <vxe-table-column 
                field="isin" 
                title="Currency" 
                width="100">
            </vxe-table-column>
            <vxe-table-column 
                title="Variation Margin Amount" 
                min-width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.q"
                        :decimals="2"
                        color
                        sign/>
                </template>
                <template #footer="{ items, _columnIndex }">
                    <FormattedNumber
                        :value="items[_columnIndex]"
                        :decimals="2"
                        color
                        sign/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                title="Prev Day Position" 
                width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.props.prev_q"
                        color/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                title="Previous Day Price" 
                width="140"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.props.prev_price"
                        :decimals="4"/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                title="Position" 
                width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.props.q"
                        color/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                title="Price" 
                width="140"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.props.price"
                        :decimals="4"/>
                </template>
            </vxe-table-column>
        </vxe-table>
    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    name: 'accounting_fifo',
    components: { PageBlock, FormattedNumber, FormattedDate },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        isin: {
            required: true,
        },
        pt: {
            required: true,
        },
        period_from: {
            required: true,
        },
        period_to: {
            required: true,
        },
        instrument: {
            required: true,
        },
    },

    data(){
        return {
            list           : [],
            loading        : false,
            portfolio_mode : 'trade'
        }
    },

    methods: {
        get_list(){
            this.loading = true
            let period_from = this.$moment(this.period_from).format("YYYY-MM-DD")
            let period_to = this.$moment(this.period_to).format("YYYY-MM-DD")
            this.$store.dispatch('instrument/getVarmargin', {
                isin          : this.isin,
                mode          : this.portfolio_mode,
                client_id     : this.clientid,
                portfolio_id  : this.portfolioid,
                period_from   : period_from,
                period_to     : period_to,
                position_type : this.pt,
            }).then((response) => {
                this.list = response
                this.loading = false
            })
        },

        sum_q (list) {
            let count = 0
            list.forEach(item => {
                count += item.q
            })
            return count
        },

        footer_method({ columns, data }){
            let footer_row = []
            columns.forEach((column, columnIndex) => {
                if (columnIndex === 0) {
                    footer_row.push('Total')
                }
                else if (columnIndex === 2) {
                    footer_row.push(this.sum_q(data))
                }
                else {
                    footer_row.push('')
                }
            })
            return [footer_row]
        },
    },

    mounted(){
        this.get_list();
    }
}
</script>
