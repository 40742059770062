<template>
    <PageBlock 
        title="Accruals and Cash Income"
        :loading="loading"
        can-export
        can-fullscreen>

        <vxe-table 
            :data="list" 
            size="mini" 
            class="common-grid" 
            v-loading="loading"
            auto-resize
            :footer-method="footer_method"
            show-footer
            footer-row-class-name="common-grid__footer">

            <vxe-table-column 
                field="exp_dt" 
                title="Cut-Off Date" 
                min-width="100"
                formatter="formatDate">
            </vxe-table-column>
            <vxe-table-column 
                field="exp_q" 
                title="Position" 
                min-width="100"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.exp_q"
                        :decimals="2"
                        color
                        sign/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                field="exp_per_one" 
                title="Accruals Amount per One" 
                min-width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.exp_per_one"
                        :decimals="4"/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                field="exp_cur" 
                title="Accruals Currency" 
                min-width="100">
            </vxe-table-column>
            <vxe-table-column 
                field="exp_am" 
                title="Accruals Amount" 
                min-width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.exp_am"
                        :decimals="2"
                        color
                        sign/>
                </template>
                <template #footer="{ items, _columnIndex }">
                    <FormattedNumber
                        :value="items[_columnIndex]"
                        :decimals="2"
                        color
                        sign/>
                </template>
            </vxe-table-column>
            <vxe-table-column 
                field="fact_dt" 
                title="Cash Income Date" 
                min-width="100"
                formatter="formatDate">
            </vxe-table-column>
            <vxe-table-column 
                field="fact_cur" 
                title="Cash Income Currency" 
                min-width="100">
            </vxe-table-column>
            <vxe-table-column 
                field="fact_am" 
                title="Cash Income Amount" 
                min-width="150"
                align="right">
                <template #default="{ row }">
                    <FormattedNumber
                        :value="row.fact_am"
                        :decimals="2"
                        color
                        sign/>
                </template>
                <template #footer="{ items, _columnIndex }">
                    <FormattedNumber
                        :value="items[_columnIndex]"
                        :decimals="2"
                        color
                        sign/>
                </template>
            </vxe-table-column>
        </vxe-table>

    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    name: 'accruals_cash_income',
    components: { PageBlock, FormattedNumber, FormattedDate },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        isin: {
            required: true,
        },
        pt: {
            required: true,
        },
        period_from: {
            required: true,
        },
        period_to: {
            required: true,
        },
        instrument: {
            required: true,
        },
    },

    data(){
        return {
            list           : [],
            loading        : false,
        }
    },

    methods: {
        get_list(){
            this.loading = true
            let period_from = this.$moment(this.period_from).format("YYYY-MM-DD")
            let period_to = this.$moment(this.period_to).format("YYYY-MM-DD")
            this.$store.dispatch('instrument/getAccrualsCashIncome', {
                isin          : this.isin,
                client_id     : this.clientid,
                portfolio_id  : this.portfolioid,
                period_from   : period_from,
                period_to     : period_to,
                position_type : this.pt,
            }).then((response) => {
                this.list = response
                this.loading = false
            })
        },

        sum_exp_am (list) {
            let count = 0
            list.forEach(item => {
                count += item.exp_am
            })
            return count
        },

        sum_fact_am (list) {
            let count = 0
            list.forEach(item => {
                count += item.fact_am
            })
            return count
        },

        footer_method({ columns, data }){
            let footer_row = []
            columns.forEach((column, columnIndex) => {
                if (columnIndex === 0) {
                    footer_row.push('Total')
                }
                else if (columnIndex === 4) {
                    footer_row.push(this.sum_exp_am(data))
                }
                else if (columnIndex === 7) {
                    footer_row.push(this.sum_fact_am(data))
                }
                else {
                    footer_row.push('')
                }
            })
            return [footer_row]
        },
    },

    mounted(){
        this.get_list();
    }
}
</script>
