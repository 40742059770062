<template>
    <el-date-picker
        v-model="period"
        type="daterange"
        range-separator="To"
        align="right"
        unlink-panels 
        :clearable="false"
        :picker-options="picker_options" 
        @change="period_change">
    </el-date-picker>
</template>

<script>
export default {
    props: {
        period_from: {
            required: true,
        },
        period_to: {
            required: true,
        },
    },

    data(){
        return {
            period: [this.period_from, this.period_to],
        }
    },

    computed: {
        picker_options(){
            let $this = this
            return {
                shortcuts: [{
                    text: 'Today',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: 'Yesterday',
                    onClick(picker) {
                        const end = $this.$moment().add({days:-1}).toDate();
                        const start = $this.$moment().add({days:-1}).toDate();
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = $this.$moment().add({days:-7}).toDate();
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = $this.$moment().add({months:-1}).toDate();
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = $this.$moment().add({months:-3}).toDate();
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: 'Last 6 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = $this.$moment().add({months:-6}).toDate();
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: 'Last year',
                    onClick(picker) {
                        const end = new Date();
                        const start = $this.$moment().add({years:-1}).toDate();
                        picker.$emit('pick', [start, end]);
                    }
                }]
            }
        }
    },

    methods: {
        period_change(){
            this.$emit('change', this.period)
        }
    }
}
</script>
