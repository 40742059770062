<template>
    <div class="prtf-page" v-if="client && portfolio && instrument">
        <div class="prtf-page-header">
            <div class="prtf-page-header-title-holder">
                <div class="prtf-page-header-maintitle">
                    <div class="prtf-page-header-name">{{instrument.name}}</div>
                </div>
                <div class="prtf-page-header-subtitle">
                    <InstrumentHeaderLink :instrument="instrument" :position_type="pt" />
                    <ClientHeaderLink :client="client" />
                    <PortfolioHeaderLink :portfolio="portfolio" :clientid="client.id" />
                </div>
            </div>

            <div class="prtf-page-header-buttons">
                <div class="prtf-page-header-button">
                    <el-button type="text" @click="get_data"><svg-icon icon-class="refresh" /></el-button>
                </div>
                <div class="prtf-page-header-button">
                    <PeriodPicker
                        :period_from="period_from"
                        :period_to="period_to"
                        @change="change_period" />
                </div>
            </div>
        </div>

        <div class="prtf-page-body prtf-page-body-flex">
            <div class="prtf-page-item prtf-page-item-left prtf-page-item-half">
                <Trades
                    :loading="operations_loading"
                    :operations="operations"
                    ref="Trades" />
            </div>

            <div class="prtf-page-item prtf-page-item-right prtf-page-item-half">
                <AccountsMovement 
                    :clientid="client._id"
                    :portfolioid="portfolioid"
                    :isin="isin"
                    :pt="pt"
                    :asset="asset"
                    :period_from="period_from"
                    :period_to="period_to"
                    ref="AccountsMovement" />
            </div>

            <div class="prtf-page-item prtf-page-item-full">
                <Fees 
                    :clientid="client._id"
                    :portfolioid="portfolioid"
                    :portfoliocur="portfolio.currency"
                    :isin="isin"
                    :pt="pt"
                    :asset="asset"
                    :period_from="period_from"
                    :period_to="period_to"
                    ref="Fees" />
            </div>

            <div class="prtf-page-item prtf-page-item-full" v-if="asset !== 'cash' || isin !== portfolio.currency">
                 <PriceChart
                    :class_name="'prtf-instrument-price-chart'"
                    :operations="operations"
                    :use_markers="use_price_chart_markers"
                    :isin="price_isin"
                    :asset="asset"
                    :period_from="period_from"
                    :period_to="period_to"
                    ref="PriceChart" />
            </div>

            <div class="prtf-page-item prtf-page-item-full" v-if="asset === 'future'">
                <FutureVarmargin
                    :clientid="client._id"
                    :portfolioid="portfolioid"
                    :isin="price_isin"
                    :operations_isin="isin"
                    :pt="pt"
                    :period_from="period_from"
                    :period_to="period_to" 
                    :instrument="instrument"
                    ref="FutureVarmargin" />
            </div>

            <div class="prtf-page-item prtf-page-item-full" v-if="asset === 'equity' || asset === 'bond'">
                <AccrualsAndCashIncome
                    :clientid="client._id"
                    :portfolioid="portfolioid"
                    :isin="price_isin"
                    :operations_isin="isin"
                    :pt="pt"
                    :period_from="period_from"
                    :period_to="period_to" 
                    :instrument="instrument"
                    ref="AccrualsAndCashIncome" />
            </div>
        </div>
    </div>
</template>

<script>
import PeriodPicker from '@/components/PageComponents/PeriodPicker'
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'
import InstrumentHeaderLink from '@/components/PageComponents/InstrumentHeaderLink'
import PortfolioHeaderLink from '@/components/PageComponents/PortfolioHeaderLink'

import Trades from './Trades'
import PriceChart from "@/components/PriceChart";
import FutureVarmargin from './FutureVarmargin'
import AccountsMovement from './AccountsMovement'
import AccrualsAndCashIncome from './AccrualsAndCashIncome'
import Fees from './Fees'

export default {
    name: 'portfolio_instrument',
    components: { 
        PeriodPicker, 
        ClientHeaderLink, 
        InstrumentHeaderLink, 
        PortfolioHeaderLink, 
        Trades, 
        PriceChart, 
        FutureVarmargin, 
        AccountsMovement, 
        AccrualsAndCashIncome, 
        Fees, 
    },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        isin: {
            required: true,
        },
        pt: {
            required: true,
        },
        asset: {
            required: true,
        },
    },

    data(){
        return {
            client                  : undefined,
            portfolio               : undefined,
            instrument              : undefined,
            period_from             : this.$moment().add({months:-1}).toDate(),
            period_to               : this.$moment().toDate(),
            use_price_chart_markers : true,
            operations              : [],
            operations_loading      : false
        }
    },

    computed: {
        price_isin(){
            if (this.asset === 'cash')
                return this.isin + this.portfolio.currency;
            return this.isin
        }
    },

    methods: {
        change_period($event){
            this.period_from = $event[0];
            this.period_to   = $event[1];

            this.$nextTick(function () {
                this.get_data();
            });
        },

        get_data(){
            this.get_operations()
            if (this.$refs.PriceChart) this.$refs.PriceChart.get_list();
            if (this.$refs.FutureVarmargin) this.$refs.FutureVarmargin.get_list();
            if (this.$refs.AccountsMovement) this.$refs.AccountsMovement.get_list();
            if (this.$refs.AccrualsAndCashIncome) this.$refs.AccrualsAndCashIncome.get_list();
            if (this.$refs.Fees) this.$refs.Fees.get_list();
        },

        get_operations(){
            this.operations_loading = true
            let search_params = {
                asset        : this.asset,
                portfolio_id : this.portfolioid,
                client_id    : this.client._id,
                date_from    : this.period_from,
                date_to      : this.period_to,
            }

            if (this.asset === 'future') search_params.dscode = this.isin;
            else search_params.isin = this.isin;

            if (this.pt === 'short')
                search_params.t = {'$in': ['sell_short', 'cover_short', 'dividend']};
            else
                search_params.t = {'$nin': ['sell_short', 'cover_short']};

            return this.$store.dispatch('portfolioOperations/getList', search_params).then((response) => {
                this.operations = response
                this.operations_loading = false
            })
        }
    },

    mounted(){
        if (this.clientid && this.portfolioid && this.isin) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                this.$store.dispatch('portfolio/getPortfolio', this.portfolioid).then((portfolio_response) => {
                    this.portfolio = portfolio_response
                    this.$store.dispatch('instrument/getInstrumentShort', {isin: this.isin, asset:this.asset}).then((instrument_response) => {
                        this.instrument = instrument_response
                        this.$emit('tab-title-change', this.instrument.isin + ' in ' + this.portfolio.name)
                        this.get_data();
                    })
                })
            })
        }
    }
}
</script>
