<template>
    <PageBlock 
        title="Accounts Movement"
        :loading="loading"
        can-export
        can-fullscreen
        is-table
        :list="list">

        <template v-slot:buttons>
            <div class="prtf-page-item-data-btn"><el-select v-model="portfolio_mode" @change="get_list" size="mini">
                <el-option label="By value date" value="value"></el-option>
                <el-option label="By trade date" value="trade"></el-option>
            </el-select></div>
            <div class="prtf-page-item-data-btn"><el-select v-model="account" @change="get_list" size="mini">
                <el-option 
                    v-for="item in accounts"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id">
                </el-option>
            </el-select></div>
        </template>
            
        <vxe-table-column 
            field="dt" 
            title="Date" 
            width="120"
            formatter="formatDateTime">
        </vxe-table-column>
        <vxe-table-column 
            title="Amount" 
            min-width="150"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.q"
                    :decimals="2"
                    color
                    sign/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Info" 
            field="f"
            min-width="150"
            align="right">
            <template #default="{ row }">
                <span v-if="row.f === 'vm'">var. margin</span>
                <span v-else-if="row.f === 'cms'">comission</span>
                <span v-else>{{row.f}}</span>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Balance" 
            min-width="150"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.b"
                    :decimals="2"
                    color
                    sign/>
            </template>
        </vxe-table-column>
    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    name: 'isin_accounts_movement',
    components: { PageBlock, FormattedNumber, FormattedDate },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        isin: {
            required: true,
        },
        pt: {
            required: true,
        },
        asset: {
            required: true,
        },
        period_from: {
            required: true,
        },
        period_to: {
            required: true,
        },
    },

    data(){
        return {
            list            : [],
            loading         : false,
            portfolio_mode  : 'trade',
            account         : undefined,
            accounts        : [],
        }
    },

    methods: {

        get_list(){
            this.loading = true
            let search_params = {
                isin         : this.isin,
                client_id    : this.clientid,
                date_from    : this.period_from,
                date_to      : this.period_to,
                mode         : this.portfolio_mode,
                account      : this.account,
            }

            this.$store.dispatch('instrument/getAccountMovemements', search_params).then((response) => {
                this.list = response
                this.loading = false
            })
        },

        define_account(){
            this.$store.dispatch('portfolioAccounts/getList', {
                portfolio_id : this.portfolioid,
            }).then((response) => {
                this.accounts = response.filter(item => !item.inactive)
                if (this.accounts.length)
                    this.account = this.accounts[0]._id;
                this.get_list()
            })
        },
    },

    mounted(){
        this.define_account()
    }
}
</script>
